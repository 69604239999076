* {
  margin: 0;
  padding: 0;
}

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calculator {
  height: 445px;
  max-width: 460px;
  border-radius: 4px;
  background-color: rgb(7, 26, 82);
}

.output {
  height: 80px;
}

.keys {
  gap: 5px;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.keys button:first-child {
  color: #ffffff;
  grid-column-end: 3;
  grid-column-start: 1;
  background-color: #881407;
}

.keys button:nth-child(15) {
  color: #ffffff;
  grid-row: 4 / 6;
  grid-column: 4 / 4;
  background-color: rgb(9, 109, 231);
}

.keys button:nth-child(16) {
  grid-column-start: 1;
  grid-column-end: 3;
}

.input,
.result {
  color: #fff;
  height: 40px;
  display: block;
  font-size: 40px;
  text-align: right;
  padding-right: 10px;
}

button {
  border: none;
  font-size: 28px;
  min-width: 80px;
  min-height: 65px;
  border-radius: 4px;
}
